<template>
  <v-card height='912px' class="background-app" id="bg">
    <v-layout align-center justify-center>
      <v-card width="450px" shaped dense elevation="24"  style='margin-top:18%'>
        <v-form ref="form" v-model="valid" lazy-validation class="pa-5">
          <v-row>
            <v-col cols="4">
                <img style="margin-left:-20px" src="@/assets/Kansen.png" width="180%"/>
                <!-- <h6>CHANGE PHOTO INTO YOUR ICON</h6> -->
            </v-col>
            <v-col cols="6" class="ml-12">
              <v-text-field
                  autocomplete="off"
                  hide-details
                  placeholder="Username"
                  prepend-inner-icon="mdi-account"
                  style="width: 100%"
                  v-model="username"
                  :rules="usernameRules"
                  label="業者コード"
                  dense
                  outlined
                  required
              ></v-text-field>
              <br/>
              <!-- :type="fieldTypes.password" -->
              <v-text-field
                  @click="fieldTypes.password = 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 == !show1"
                  :type="show1 ? 'text' : fieldTypes.password"
                  prepend-inner-icon="mdi-lock"
                  style="width: 100%"
                  v-model="password"
                  :rules="passwordRules"
                  label="パスワード"
                  @focus="handleType"
                  @blur="handleType"
                  dense
                  outlined
                  required
              ></v-text-field>
                  <v-btn width="100%" color="success" class="mr-4" @click="validate()" >ログイン</v-btn>
              <!-- <center>
                <v-btn text color="success" @click="openRegisterDialog()" style="margin-top:3%"> 
                  <v-icon>mdi-plus</v-icon>register</v-btn>
              </center> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-layout>

    
  </v-card>
  
  
</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2'

export default {
 
  name: 'app',
  data: () => ({
    deletedUsersData:[],
  listOfExistingUser:[],
  name:'',
  registerUsername:'',
  registerPassword:'',
  typeOfaccount:'',
  // accountType:['Subcon_User','Standard'],
  rules: [(v) => !!v || "Field is required"],
  userDialoginLogin:false,
  userData:[],
  position:['001','004','007'],
  DepartmentCode:'',
    show1: false,
    valid: false,
    password: "",
    username: "",
    fieldTypes: {
      // add this for change input type
      password: "text",
    },
    usernameRules: [(v) => !!v || "Username is required"],
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  methods: {
    popMaintenance(){
        Swal.fire({
            toast: true,
            position: "top",
            html:"システムメンテナンス中です \n (System Under Maintenance)",
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000,
            color:'red',
          })
    },
    openRegisterDialog(){
      this.userDialoginLogin = true
    },

    clearRegistration(){
        // this.employeeNo = ''
      
        this.registerUsername = ''
        this.registerPassword = ''
        this.typeOfaccount = ''
        this.userDialoginLogin = false
        // location.reload()
      },

    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },




    validate() {
        this.$refs.form.validate();
        this.employeeMaster(this.username && this.password)
    },

    async  employeeMaster ()  {
      if(this.username.length <= 6){
        await  this.loadUsers(this.username)
      }else{
         await this.testLoadExistingUser(this.username)
      }
        
        
    },

    async testLoadExistingUser(userdata){
      const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');
            const date = `${year}-${month}-${day}`;
  try {
        const [subcon318, subcon318RegisteredAccount] = await Promise.all([
          axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getSubcon318/${userdata}`,
            headers: {
              'x-api-key': this.awsHeaders
            }
          }),
          axios({
            method: 'get',
            url: `${this.$BaseUrl}/api/getRegisteredSubcon318Account/${userdata}`,
            headers: {
              'x-api-key': this.awsHeaders
            }
          })
        ]);
          let storeData ={
                  username: userdata,
                  password: userdata,
                  role : 'Subcon User',
                }

            let dataToBeinsert = {
              username : userdata,
              password : userdata,
              date_registered : date
            }

            
          if( subcon318.data.length === 1 && subcon318RegisteredAccount.data.length === 0){
    
          await axios({
                                  method:'post',
                                  url:`${this.$BaseUrl}/api/insertSubconUser`,
                                  data:dataToBeinsert,
                                  headers: {
                                                      'x-api-key' : this.awsHeaders
                                                  }
                              }).then(()=>{
                                Swal.fire({
                                  toast: true,
                                  position: "center-start",
                                  icon: 'success',
                                  text: "ようこそ" ,
                                  showConfirmButton: false,
                                  timer: 3000,
                                  color:'green',
                                  })
                                  this.$store.commit("STORE_USER",storeData);
                                  this.$router.push("/Subconrequest")
                              }) 
                            
          }else if(subcon318.data.length === 1 && subcon318RegisteredAccount.data.length === 1){
            let storeExistingData = {  username: userdata,
                  password:  subcon318RegisteredAccount.data[0].password,
                  role : 'Subcon User',
                  id : subcon318RegisteredAccount.data[0].id

                  }
            const  existingAccount = await subcon318RegisteredAccount.data.some(a => a.username == this.username && a.password == this.password)
            
                if(existingAccount && subcon318RegisteredAccount.data[0].accountStatus === 0){
                  let updateStatus = { username : this.username, status : 1}
                            axios({
                                    method:'post',
                                    url:`${this.$BaseUrl}/api/updateSubconActiveUser`,
                                    data:updateStatus,
                                    headers: {
                                                        'x-api-key' : this.awsHeaders
                                                    }
                                }) 
                      Swal.fire({
                                  toast: true,
                                  position: "center-start",
                                  icon: 'success',
                                  title: "ようこそ " + userdata ,
                                  showConfirmButton: false,
                                  timer: 3000,
                                  color:'green',
                                });
                      this.$store.commit("STORE_USER",storeExistingData);
                      this.$router.push("/Subconrequest")
                }else if (subcon318RegisteredAccount.data[0].accountStatus === 1){
                      Swal.fire({
                                      toast: true,
                                      position: "center-start",
                                      icon: 'warning',
                                      text: "アカウントは現在アクティブです。しばらくしてからもう一度お試しください" ,
                                      showConfirmButton: false,
                                      timer: 3000,
                                      color:'warning',
                                      })
                  // Account currently active. Please try again later
                  // アカウントは現在アクティブです。しばらくしてからもう一度お試しください。
                }else {
                  Swal.fire({
                            toast: true,
                            position: "center-start",
                            icon: 'warning',
                            html: "<center>Log-in failed!<br>不適切なユーザー名またはパスワード</center>",
                            showConfirmButton: false,
                            timer: 3000,
                            color:'red',
                            });
                }
          }
          // console.log(subcon318RegisteredAccount.data[0].accountStatus);
  } catch (error) {
    // Handle errors from any of the requests
    console.error('Error:', error);
  }

  

    },


      loadUsers(username){
            axios({
              method: 'get',
              url: `${this.$BaseUrl}/api/getallUser/${username}`,
              headers: {
                'x-api-key': this.awsHeaders
              }
            }).then(res => {
              this.listOfExistingUser = res.data

            const exists =  this.listOfExistingUser.some(a => a.username == this.username && a.password == this.password );
            const recordfound =  this.listOfExistingUser.find(a => a.username == this.username && a.password == this.password)
          
              if(exists){
                if(recordfound.role == 'System Admin'){
                        Swal.fire({
                                  toast: true,
                                  position: "center-start",
                                  icon: 'success',
                                  title: "Log in as System Admin" ,
                                  showConfirmButton: false,
                                  timer: 3000,
                                  color:'green',
                                  });
                                    this.$store.commit("STORE_USER",recordfound);
                                    this.$router.push("/Subconrequest")
                }else if (recordfound.role == 'Kansen User' || recordfound.role == 'Kansen Admin'){
                    if(recordfound.accountStatus == 1){
                        Swal.fire({
                                    toast: true,
                                    position: "center-start",
                                    icon: 'warning',
                                    html: "<center>Log-in failed!<br>Account is currently in use</center>",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    color:'red',
                                    })
                    }else{
                      let updateStatus = { id : recordfound.id, status : 1}

                        axios({
                                  method:'post',
                                  url:`${this.$BaseUrl}/api/updateActiveUser`,
                                  data:updateStatus,
                                  headers: {
                                                      'x-api-key' : this.awsHeaders
                                                  }
                              }) 

                          Swal.fire({
                              toast: true,
                              position: "center-start",
                              icon: 'success',
                              html: "<center>Welcome to Kansen Sytem</center>",
                              showConfirmButton: false,
                              timer: 3000,
                              color:'green',
                              });
                                          this.$store.commit("STORE_USER",recordfound);
                                          this.$router.push("/Kansensystem")
                          }
                    
                }
                                  
              }
            }).catch(error => {
              console.error("Error fetching data:", error);
            });
                
          },

      loadSubcon318(subconCode){
            axios({
              method: 'get',
              url: `${this.$BaseUrl}/api/getSubcon318/${subconCode}`,
              headers: {
                'x-api-key': this.awsHeaders
              }
            }).then((result) => {
              if(result.data.length > 0){
                  let storeData ={
                  username: subconCode,
                  password: subconCode,
                  role : 'Subcon User'
                }
                Swal.fire({
                                  toast: true,
                                  position: "center-start",
                                  icon: 'success',
                                  title: "ようこそ " + subconCode ,
                                  showConfirmButton: false,
                                  timer: 3000,
                                  color:'green',
                                  });
                    this.$store.commit("STORE_USER",storeData);
                    this.$router.push("/Subconrequest")
              }else{
                Swal.fire({
                            toast: true,
                            position: "bottom",
                            icon: 'warning',
                            html: "<center>Log-in failed!<br> データが見つかりませんでした！</center>",
                            showConfirmButton: false,
                            timer: 3000,
                            color:'red',
                            });
              }
                
            }).catch((err) => {
              console.log(err);
            });
      }


      }, //end of methods



};
</script>

<style>

.toolbar{
    width: 100% !important;
    background-color:rgb(26, 32, 38);
}
.main-content{
    width: 25% !important;
    border : 1px solid !important;
    margin: 15em auto !important;
    display: flex !important;
}
.background-app {
    background-image:url(https://s3.ap-southeast-1.amazonaws.com/kansen.ihs-w.com/1.KansenSystemFiles/kansenBackround.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>